import React from "react";
import Helmet from "react-helmet";
import PromoBar from "./PromoBar";
import Navbar from "./navbar";
import favicon from "../img/favicon-32x32.png";

export default class Header extends React.Component {
    constructor(props) {
        let userTrack = {};
        if (typeof window !== "undefined") {
            userTrack = JSON.parse(localStorage.getItem("userTrack")) ?? {
                usertype: 0,
            };
        }
        super(props);
        this.state = {
            showChat: Boolean(userTrack.usertype % 2 !== 0),
        };
    }

    componentDidUpdate() {
        console.log(
            "componentDidMount fireddddddddddddddddddddddddddddddddddddddd"
        );
    }
    render() {
        let loadedScript = "";
        let title =
            typeof this.props.page.title === "undefined"
                ? this.props.siteMetadata.title
                : this.props.page.title;

        let description =
            typeof this.props.page.description === "undefined"
                ? this.props.siteMetadata.description
                : this.props.page.description;
        let keywords =
            typeof this.props.page.keywords === "undefined"
                ? this.props.siteMetadata.keywords
                : this.props.page.keywords;

        let ogTitle =
            typeof this.props.page.ogTitle === "undefined"
                ? this.props.siteMetadata.ogTitle
                : this.props.page.ogTitle;

        let ogDescription =
            typeof this.props.page.ogDescription === "undefined"
                ? this.props.siteMetadata.ogDescription
                : this.props.page.ogDescription;

        let ogImage =
            typeof this.props.page.headerImage === "undefined"
                ? this.props.siteMetadata.headerImage
                : this.props.page.headerImage;
        let contextData = this.props.contextData;

        let setNoIndex =
            typeof this.props.contextData.noIndex === "undefined"
                ? false
                : this.props.contextData.noIndex;
        // console.log("log9");

        // console.table("title", title);
        // console.table("description", description);
        // console.table("keywords", keywords);
        // console.table("ogTitle", ogTitle);
        // console.table("ogDescription", ogDescription);
        // console.table("ogImage", ogImage);
        // console.table("contextData", contextData);

        // console.log("showchat external", this.state);

        function loadScriptFile(scriptPath, jsFile, callBack) {
            // setTimeout(() => {
            if (
                typeof window !== "undefined" &&
                typeof document !== "undefined"
            ) {
                // console.log(
                //     "!document.getElementsByClassName('xenioo-chat-widget')",
                //     !document.getElementsByClassName("xenioo-chat-widget")
                // );
                // console.log(
                //     'document.getElementsByClassName("xenioo-chat-widget").length',
                //     document.getElementsByClassName("xenioo-chat-widget")
                //         ? document.getElementsByClassName("xenioo-chat-widget")
                //               .length
                //         : 0
                // );
                let path = window.location.pathname;
                path = path.replace(/\//g, "");

                // const removeArray = [
                //     "schliessanlagenpreisrechner",
                //     "videoueberwachungpreisrechner",
                //     "alarmanlagenpreisrechner",
                //     "brandmeldeanlagenpreisrechner",
                //     "preisrechner",
                // ];
                // if (document.getElementById("xenioo-chat-widget")) {
                //     if (removeArray.includes(path)) {
                //         document.getElementById(
                //             "xenioo-chat-widget"
                //         ).style.display = "none";
                //     } else {
                //         document.getElementById(
                //             "xenioo-chat-widget"
                //         ).style.display = "block";
                //     }
                // }
                if (
                    !document.getElementsByClassName("xenioo-chat-widget") ||
                    document.getElementsByClassName("xenioo-chat-widget")
                        .length == 0
                ) {
                    var scriptTag = document.createElement("script"); //creates a HTML script element
                    scriptTag.language = "JavaScript"; //sets the language attribute
                    scriptTag.type = "text/javascript";
                    scriptTag.id = "xeniooChatScript";
                    scriptTag.src = scriptPath + jsFile + ".js"; //the source
                    if (callBack) {
                        scriptTag.onload = callBack; //when loaded execute call back
                    }
                    var scriptTagParent =
                        document.getElementsByTagName("script")[0];
                    if (scriptTagParent) {
                        scriptTagParent.parentNode.insertBefore(
                            scriptTag,
                            scriptTagParent
                        );
                    } else {
                        document.body.appendChild(scriptTag);
                    }
                }
            }
            // }, 1000);
        }
        return (
            <header className="header">
                <Helmet>
                    <html className="has-navbar-fixed-top" />
                    {/* <script src={"/js/redirects.js"} /> */}
                    <script src={"/js/hotjar.js"} />
                    {/* <script src={"/js/reviews.js"} /> */}
                    {/* {this.state.showChat
                        ? loadScriptFile("/js/", "xeniooChat", () => {})
                        : ""} */}
                    {loadScriptFile("/js/", "xeniooChat", () => {})}
                    <meta
                        name="p:domain_verify"
                        content="4c27de4f646b48e99e1d143249d9bcf7"
                    />
                    <script
                        id="_etLoader"
                        type="text/javascript"
                        charset="UTF-8"
                        data-block-cookies="true"
                        data-respect-dnt="true"
                        data-secure-code="3X9hj9"
                        src="//code.etracker.com/code/e.js"
                        async
                    />
                    {/* <script
                        id="reviews-id"
                        type="text/javascript"
                        charset="UTF-8"
                        data-block-cookies="true"
                        data-respect-dnt="true"
                        src="//siegel.ausgezeichnet.org/widgets/js/5ab4e3cd4c741772b64b09d2/reviewSlider.js?global=false&useEnglish=false"
                        async
                    /> */}
                    <title>{title} | MeinAlarm24</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                    <meta property="og:type" content="article" />
                    <meta
                        property="og:title"
                        content={ogTitle + " | MeinAlarm24"}
                    />
                    <meta property="og:description" content={ogDescription} />
                    <meta
                        name="facebook-domain-verification"
                        content="kgikcqnnb2ox92k6djusmz6pqm84ly"
                    />
                    <meta property="og:image" content={ogImage} />
                    <link rel="icon" href={favicon} />
                    {(setNoIndex ||
                        process.env.GATSBY_NODE_ENV === "staging") && (
                        <meta name="robots" content="noindex" />
                    )}
                </Helmet>
                <PromoBar context={contextData} />
                <Navbar context={contextData} />
            </header>
        );
    }
}
